import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash/fp";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import BlogPost from "../../components/BlogPost";
import IntroHeader from "../../components/IntroHeader";
import { Container, Section } from "../../components/LayoutUtils";
import AllBlogPosts from "../../components/AllBlogPosts";
import Layout from "../../layouts/index";
import getPageImage from "../../utils/getPageImage";

const BlogPage = ({ data }) => {
  const post = get("latestBlogPost.edges[0].node", data);
  const prev = get("latestBlogPost.edges[1].node", data);
  return (
    <Layout>
      <SEO
        title="Tailors blog - latest news from the cutting board"
        image={getPageImage(data.page)}
        description={post.body.markdown.excerpt}
      />
      <IntroHeader {...data.page} useH2 />
      <Container>
        <Section>
          <div className="row">
            <div className="col-md-4 d-none d-sm-none d-md-block">
              <AllBlogPosts currentPost={post} />
            </div>
            <div className="col-sm-12 col-md-8">
              <BlogPost post={post} prev={prev} />
            </div>
          </div>
        </Section>
      </Container>
    </Layout>
  );
};

BlogPage.propTypes = {
  data: PropTypes.shape({
    latestBlogPost: PropTypes.shape({
      edges: PropTypes.array
    })
  }).isRequired
};

export const query = graphql`
  {
    latestBlogPost: allContentfulBlogPost(
      sort: { fields: [publishAt], order: DESC }
      filter: { published: { eq: true } }
      limit: 2
    ) {
      edges {
        node {
          ...blogPost
        }
      }
    }
    page: contentfulPage(slug: { eq: "blog" }) {
      ...page
    }
    ...allPublishedBlogPosts
  }
`;

export default BlogPage;
